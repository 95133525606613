<template>
  <div id="app">
    <div class="inputs">
      <div class="inputs-text">
        <input
          type="textarea"
          v-model="question"
          name=""
          id=""
          placeholder="Questions"
        />
        <input
          type="textarea"
          v-model="response"
          name=""
          id=""
          placeholder="response"
        />
      </div>

      <div class="add" @click="addItem" :disabled="!question && !response">
        Add
      </div>
    </div>
    <div class="questions-container">
      <div
        class="question"
        v-for="(question, i) in questions"
        :key="question._id"
      >
        <!-- <span>{{ i + 1 }}</span> -->
        <div class="content-container">
          <input
            type="text"
            name=""
            id=""
            v-if="isSelected(question)"
            v-model="editedQuestion"
          />
          <p v-else class="question-question">
            {{ question.question }}
          </p>
          <input
            type="text"
            name=""
            id=""
            v-if="isSelected(question)"
            v-model="editedResponse"
          />
          <p v-else class="question-reponse">
            {{ question.response }}
          </p>
        </div>

        <div class="question-controller">
          <span
            class="material-icons"
            :class="isSelected(question) ? 'cross' : 'edit'"
            @click="isSelected(question) ? unselect() : select(question)"
          >
            {{ isSelected(question) ? "close" : "edit" }}
          </span>
          <span
            class="material-icons"
            :class="isSelected(question) ? 'save' : 'delete'"
            @click="
              isSelected(question)
                ? updateItem(question, i)
                : deleteQuestion(question, i)
            "
          >
            {{ isSelected(question) ? "save" : "delete" }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "App",
  data() {
    return {
      questions: [],
      question: "",
      response: "",
      editedQuestion: "",
      editedResponse: "",
      selected: {},
      path: "https://server-questions-judas.r-selwa.space/api/questionItems/",
      // path: "http://localhost:6003/api/questionItems/",
    };
  },
  methods: {
    async addItem() {
      const response = await axios.post(this.path, {
        question: this.question,
        response: this.response,
      });
      this.questions.push(response.data);
      this.question = "";
      this.response = "";
    },
    async deleteQuestion(question, i) {
      await axios.delete(this.path + question._id);
      this.questions.splice(i, 1);
    },
    select(question) {
      this.selected = question;
      this.editedQuestion = question.question;
      this.editedResponse = question.response;
    },
    isSelected(question) {
      return question._id == this.selected._id;
    },
    unselect() {
      this.selected = {};
      this.editedQuestion = "";
      this.editedResponse = "";
    },
    async updateItem(question, i) {
      console.log(question, i);
      const response = await axios.put(this.path + question._id, {
        question: this.editedQuestion,
        response: this.editedResponse,
      });
      this.questions[i] = response.data;
      this.unselect();
    },
  },
  async mounted() {
    // const response = await axios.get('api/questionItems')
    const response = await axios.get(this.path);
    this.questions = response.data;
  },
};
</script>

<style lang="scss">
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: 0.2s;
}
input {
  padding: 5px;
  margin: 5px 0;
  outline: none;
  border: none;
  background-color: transparent;
  border-bottom: 1px solid #222;
  &:focus {
    font-weight: bold;

    border-bottom: 2px solid #555;
  }
}
.edit {
  color: rgb(255, 113, 217);
  &:hover {
    color: rgb(219, 109, 255);
  }
}
.delete {
  color: #222;
  &:hover {
    color: red;
  }
}
.cross {
  color: #222;
  &:hover {
    color: red;
  }
}
.save {
  color: rgb(38, 255, 85);
  &:hover {
    color: rgb(61, 255, 145);
  }
}

body {
  height: 100vh;
  font-family: "Roboto";
  color: #222;

  #app {
    height: 100%;
    width: 80%;
    margin: auto;
    .inputs {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 30px;
      .inputs-text {
        display: flex;
        flex-direction: column;
        width: 70%;
      }
      .add {
        border-radius: 5px;
        padding: 5px 15px;
        color: white;
        background-color: rgb(122, 167, 255);
        cursor: pointer;
        &:hover {
          background-color: rgb(160, 192, 255);
        }
      }
    }
  }
  .questions-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    .question {
      background-color: #eee;
      padding: 10px;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;
      .content-container {
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;

        .question-question {
        }
        .question-reponse {
          // font-weight: 200;
        }
      }
      .question-controller {
        display: flex;
        .material-icons {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
